import { Col, Container, Row } from 'react-bootstrap';

const UnsubscribeSuccess: React.FC = () => {
  return (
    <>
      <Container className="py-5">
        <div className="main-content">
          <Row>
            <Col xs={12}>
              <p className="bold-header">You've been unsubscribed.</p>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default UnsubscribeSuccess;
