import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { STORM_GA_PROPERTY_ID, emailRegex } from '../../constants/index';
import { ISubscriberVerificationFormModel } from '../../types/form';
import { GoogleAnalyticsEvent } from '../../windowConfig';
import defaultHeaders from '../shared/DefaultHeaders';
import Footer from 'components/Footer';
import usePageView from '../../hooks/usePageView';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { isAxiosErrorWithCode } from '../../helpers/AxiosErrorHelper';
import useScriptTracking from 'hooks/useScriptTracking';
const unauthorizedResponseCode = 401;
const rateLimitResponseCode = 429;
const notSubscribedErrorMessage = 'Not Subscribed';
interface IVideoEntryPageProps {
  setIsSubscriber: () => void;
  videoTitle: string;
  onSignUpClick: () => void;
  setSubmittedEmailAddress: (email: string) => void;
}

const initialValues: ISubscriberVerificationFormModel = {
  emailAddress: '',
};

const request: AxiosRequestConfig = {
  method: 'POST',
  url: '/api/subscriberverification',
  headers: defaultHeaders,
};

const VideoEntryPage: React.FunctionComponent<IVideoEntryPageProps> = ({
  videoTitle,
  setIsSubscriber,
  onSignUpClick,
  setSubmittedEmailAddress,
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [apiErrorMessage, setApiErrorMessage] = useState<string | null>(null);
  const [isNotSubscriber, setIsNotSubscriber] = useState<boolean>(false);
  const location = useLocation();

  usePageView(`Video Entry Form - ${videoTitle}`, location.pathname);
  useScriptTracking();
  const onSubmit = (
    values: ISubscriberVerificationFormModel,
    formikHelpers: FormikHelpers<ISubscriberVerificationFormModel>
  ) => {
    if (!isSubmitting) {
      setApiErrorMessage(null);
      GoogleAnalyticsEvent('event', 'VerificationFormSubmitted', { send_to: STORM_GA_PROPERTY_ID });
      setIsSubmitting(true);
      Axios({ ...request, data: values })
        .then(() => {
          GoogleAnalyticsEvent('event', 'VerificationFormSuccess', { send_to: STORM_GA_PROPERTY_ID });
          setIsSubscriber();
        })
        .catch((error: Error | AxiosError) => {
          setSubmittedEmailAddress(values.emailAddress);
          if (isAxiosErrorWithCode(error, unauthorizedResponseCode)) {
            GoogleAnalyticsEvent('event', 'VerificationFormNotSubscribed', { send_to: STORM_GA_PROPERTY_ID });
            formikHelpers.setFieldError('emailAddress', notSubscribedErrorMessage);
            setIsNotSubscriber(true);
          } else if (isAxiosErrorWithCode(error, rateLimitResponseCode)) {
            setApiErrorMessage('Too many attempts. Please wait a minute and try again.');
          } else {
            setApiErrorMessage('Oops, something went wrong. Please try again.');
          }
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <h1>Hey Babe!</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <hr className="seperator"></hr>
        </Col>
      </Row>
      <Row className="main-content">
        <Col xs={12}>
          <p className="header-text">
            Enter your email address below to unlock my latest video, hope you love it! <br />
            <span className="bold">(Keep it On the JLo)</span>
          </p>
        </Col>

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={(values) => {
            const errors: { emailAddress?: string } = {};
            if (!values.emailAddress) {
              errors.emailAddress = 'Please enter your email address';
            } else if (!emailRegex.test(values.emailAddress)) {
              errors.emailAddress = 'Please enter a valid email address';
            }
            return errors;
          }}
        >
          {({ errors, touched, getFieldProps }) => (
            <Form id="SignUpForm" noValidate>
              <Row>
                <Col xs={12} md={12} lg={12} className="email-input">
                  <input
                    className={clsx({ error: errors.emailAddress && touched.emailAddress })}
                    id="emailAddress"
                    type="email"
                    placeholder="Email"
                    maxLength={254}
                    {...getFieldProps('emailAddress')}
                    required
                  />
                  {errors.emailAddress &&
                  touched.emailAddress &&
                  errors.emailAddress !== notSubscribedErrorMessage &&
                  !apiErrorMessage ? (
                    <div className="error-copy">{errors.emailAddress}</div>
                  ) : null}
                  {apiErrorMessage && <div className="error-copy">{apiErrorMessage}</div>}
                </Col>
                <Col xs={12} md={12}>
                  <button type="submit" disabled={isSubmitting}>
                    Enter
                  </button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Row>
      <Footer />

      {isNotSubscriber && (
        <Row className="main-content">
          <Col xs={12} className="error-state-subscribe text-center">
            <p className="">We couldn't find your email address on our list!</p>
            <p className="error-subheader">
              Why not <span className="bold">sign up?</span>
            </p>
          </Col>
          <Col xs={12} md={12} className="mb-4">
            <button
              className="outline-primary"
              onClick={() => {
                GoogleAnalyticsEvent('event', 'VerificationFormRedirectToSignUp', { send_to: STORM_GA_PROPERTY_ID });
                onSignUpClick();
              }}
            >
              SIGN-UP … NOW
            </button>
          </Col>
        </Row>
      )}
    </>
  );
};

export default VideoEntryPage;
