import Footer from 'components/Footer';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { IRedirectWithEmail } from 'types';

const renderEmailConfirmation = (emailAddress?: string) => {
  if (emailAddress) {
    return null;
  }
  return (
    <p className="text-center">
      An email has been sent to <span className="bold">{emailAddress}</span>
    </p>
  );
};

const SuccessPage: React.FC = () => {
  const location = useLocation();
  const { state } = location as { state: IRedirectWithEmail | null };
  return (
    <>
      <Container className="py-5 fade-in">
        <Row>
          <Col xs={12}>
            <h1>Welcome to my inner circle!</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <hr className="seperator"></hr>
          </Col>
        </Row>
        <div className="main-content">
          <Row>
            <Col xs={12}>
              <p className="bold-header">You're all signed up!</p>
              {renderEmailConfirmation(state?.email)}
              <p className="header-text">
                Check your email for regular updates on news, special experiences, exclusive content and more from JLo.{' '}
              </p>
            </Col>
          </Row>
        </div>
        <Footer />
      </Container>
    </>
  );
};

export default SuccessPage;
