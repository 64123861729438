import Axios, { AxiosRequestConfig } from 'axios';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { STORM_GA_PROPERTY_ID, emailRegex } from '../../constants/index';
import { SuccessPageRoute } from '../../constants/pageRoutes';
import { IRedirectWithEmail, IUnsubscribeFormModel } from '../../types/form';
import { GoogleAnalyticsEvent } from '../../windowConfig';
import { setIsSubscriberLocalStorage } from '../../helpers/LocalStorage';
import defaultHeaders from '../shared/DefaultHeaders';
import clsx from 'clsx';

interface IUnsubscribeFormProps {
  onSuccess?: () => void;
  parentPage: string;
  initialEmailAddress?: string;
}

const initialValues: IUnsubscribeFormModel = {
  emailAddress: '',
};

const request: AxiosRequestConfig = {
  method: 'POST',
  url: '/api/unsubscribeemail',
  headers: defaultHeaders,
};

const UnsubscribeForm: React.FC<IUnsubscribeFormProps> = ({ onSuccess, parentPage, initialEmailAddress = '' }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const navigate = useNavigate();

  const onSubmit = (values: IUnsubscribeFormModel, { resetForm }: any) => {
    if (!isSubmitting) {
      GoogleAnalyticsEvent('event', 'UnsubscribeSubmitted', { event_label: parentPage, send_to: STORM_GA_PROPERTY_ID });
      setIsSubmitting(true);
      Axios({ ...request, data: values })
        .then(() => {
          GoogleAnalyticsEvent('event', 'UnsubscribeSuccess', {
            event_label: parentPage,
            send_to: STORM_GA_PROPERTY_ID,
          });
          setIsSubscriberLocalStorage();
          if (onSuccess) {
            onSuccess();
          } else {
            const state: IRedirectWithEmail = {
              email: values.emailAddress,
            };
            navigate(SuccessPageRoute, { state });
          }
        })
        .catch(() => {
          setIsError(true);
          resetForm();
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  return (
    <>
      <div className="main-content">
        <Formik
          initialValues={{ ...initialValues, emailAddress: initialEmailAddress }}
          enableReinitialize
          onSubmit={onSubmit}
          validate={(values) => {
            const errors: { emailAddress?: string } = {};
            if (!values.emailAddress || !emailRegex.test(values.emailAddress)) {
              errors.emailAddress = 'Please enter your email address';
            } else if (!emailRegex.test(values.emailAddress)) {
              errors.emailAddress = 'Please enter a valid email address';
            }
            setIsError(false);
            return errors;
          }}
        >
          {({ errors, touched, getFieldProps }) => (
            <Form id="UnsubscribeForm" noValidate>
              <Row>
                <Col xs={12} className="email-input">
                  <input
                    id="emailAddress"
                    className={clsx({ error: errors.emailAddress && touched.emailAddress })}
                    type="email"
                    placeholder="Email"
                    maxLength={254}
                    {...getFieldProps('emailAddress')}
                    required
                  />
                  {errors.emailAddress && touched.emailAddress && !isError ? (
                    <div className="error-copy">{errors.emailAddress}</div>
                  ) : null}
                  {isError && <div className="error-copy">Oops, something went wrong. Please try again.</div>}
                </Col>
                <Col xs={12}>
                  <button type="submit" disabled={isSubmitting} className={`${isSubmitting ? 'loading' : ''}`}>
                    Submit
                  </button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default UnsubscribeForm;
