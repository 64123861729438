import CookieConsent from "react-cookie-consent";

interface CookieConsentProps {
  onCookieAccept: ()=>void
}


export default function CookieConsentPopUp({onCookieAccept}: CookieConsentProps) {
  return (
    <CookieConsent
      disableStyles
      containerClasses="cookie-container"
      buttonText="Accept"
      onAccept={onCookieAccept}
    >
      <h4 className="cookie-header">This website uses cookies.</h4>
      <p className="cookie-text">
        We use cookies to analyze website traffic and optimize your website
        experience. By accepting our use of cookies, your data will be
        aggregated with all other user data.
      </p>
    </CookieConsent>
  );
}
