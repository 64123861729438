import { Container, Col, Row } from 'react-bootstrap';
import SignUpForm from 'components/shared/SignUpForm';
import SuccessPage from './SuccessPage';
import React from 'react';
import Arrow from '../shared/Arrow';
import LogoLetters from 'components/shared/LogoLetters';
import LogoHeader from 'components/shared/LogoHeader';
import usePageView from '../../hooks/usePageView';
import Footer from 'components/Footer';
import { SuccessPageRoute, LandingPageRoute, SuccessPageTitle, LandingPageTitle } from '../../constants/pageRoutes';
import useScriptTracking from 'hooks/useScriptTracking';

import ChimneyEmbed from '../shared/ChimneyEmbed';

import '../../styles/cge-landing-style.scss';
import { GoogleAnalyticsEvent } from 'windowConfig';
import Comment from 'components/shared/Comment';
import { comments } from 'data/comments';
import SeatedWidget from 'components/shared/SeatedWidget';
import { STORM_GA_PROPERTY_ID } from 'constants/index';
import Header from 'components/shared/Header';

interface ILandingPageProps {
  success?: boolean;
}

const getPageDetails: (success: boolean) => { pageTitle: string; pagePath: string } = (success) =>
  success
    ? { pageTitle: SuccessPageTitle, pagePath: SuccessPageRoute }
    : { pageTitle: LandingPageTitle, pagePath: LandingPageRoute };

const CGELandingPage: React.FC<ILandingPageProps> = ({ success }) => {
  const pageDetails = getPageDetails(success || false);
  usePageView(pageDetails.pageTitle, pageDetails.pagePath, [success]);
  useScriptTracking([success]);

  return (
    <div className="body-content">
      <div className="jlo-logo-wrapper">
        <div className="jlo-logo-container">
          <a href="/">
            <div className="d-flex gap-2 align-items-end justify-content-center">
              <div className="">
                <LogoHeader />
                <LogoLetters />
              </div>
              <Arrow />
            </div>
          </a>
        </div>
      </div>
      <Header />

      <Container>
        {/* <section>
          <Row className="text-center mb-4">
            <Col xs={12} className="mt-2 mt-md-4">
              <h1 className="heading text-center bold">THIS IS ME…NOW</h1>
              <h1 className="subheading text-center bold mb-0">
                HEAR IT. SEE IT. LIVE&nbsp;IT…SHARE&nbsp;IT…
                <br />
              </h1>
            </Col>
          </Row>
        </section> */}
        <section className="mb-4 mb-md-5 text-center">
          <img
            className="header-image fade-in"
            width="100%"
            src="https://1770075938.rsc.cdn77.org/onthejlo/cant-get-enough-landing/images/header-artwork.webp"
            alt="Jennifer Lopez - This Is Me...Now - The Tour - Friday, June 14 Capital One Arena"
          />
        </section>

        <section className="mb-5">
          <SeatedWidget />
        </section>

        <a id="join_now"></a>
        <section className="main-content mb-5">
          <Row className="mb-2 text-center">
            <Col xs={12}>
              <img
                src="https://1770075938.rsc.cdn77.org/onthejlo/cant-get-enough-landing/images/bird.svg"
                alt="Bird Icon"
                className="bird-icon"
              />
              <h1 className="heading text-center">Join...Now</h1>
              <p className="header-text">
                Join On the JLo to get access to news, special experiences, exclusive content and more.
                <br />
                <span className="subheader-text">(Just keep it On the JLo)</span>
              </p>
            </Col>
          </Row>

          <Row className="form-wrapper mb-4 mb-sm-5">
            <Col xs={12} className="fade-in">
              {!success && <SignUpForm parentPage={LandingPageTitle} />}
              {success && <SuccessPage />}
            </Col>
          </Row>
        </section>

        <section className="comments-container">
          <Row className="text-center mb-5">
            <Col xs={12} className="fade-in">
              <img
                src="https://1770075938.rsc.cdn77.org/onthejlo/cant-get-enough-landing/images/bird.svg"
                alt="Bird Icon"
                className="bird-icon"
              />
              <div className="headline">
                <p className="headline-text">
                  "J.Lo’s ‘This Is Me...Now’ Is a Masterful, 65-Minute Rebuttal to All the Doubters"
                </p>

                <a
                  className="headline-link"
                  href="https://www.thedailybeast.com/obsessed/this-is-me-now-by-jlo-movie-review-a-massive-romantic-spectacle"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    GoogleAnalyticsEvent('event', 'TheDailyBeastReviewClick', { send_to: STORM_GA_PROPERTY_ID })
                  }
                >
                  The Daily Beast
                </a>
                <div className="headline-divider">
                  <span />
                </div>
              </div>

              <div className="headline">
                <p className="headline-text">
                  "This is Me...Now: Jennifer Lopez’s cinematic odyssey is an astonishing pop-art tour de force"
                </p>

                <a
                  className="headline-link"
                  href="https://www.telegraph.co.uk/films/0/this-is-me-now-a-love-story-jennifer-lopez-review/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    GoogleAnalyticsEvent('event', 'TheTelegraphReviewClick', { send_to: STORM_GA_PROPERTY_ID })
                  }
                >
                  The Telegraph
                </a>
                <div className="headline-divider">
                  <span />
                </div>
              </div>

              <div className="headline">
                <p className="headline-text">
                  "‘This Is Me… Now: A Love Story’ review: Jennifer Lopez’s tell-all musical flick is bonkers
                </p>

                <a
                  className="headline-link"
                  href="https://nypost.com/2024/02/14/entertainment/this-is-me-now-a-love-story-review-jennifer-lopez-tells-all/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => GoogleAnalyticsEvent('event', 'NYPostReviewClick', { send_to: STORM_GA_PROPERTY_ID })}
                >
                  NY Post
                </a>
              </div>
            </Col>
          </Row>
        </section>

        <a id="share_your_story"></a>
        <section className="main-content">
          <Row className="text-center mb-5">
            <Col xs={12} className="fade-in">
              <img
                src="https://1770075938.rsc.cdn77.org/onthejlo/cant-get-enough-landing/images/bird.svg"
                alt="Bird Icon"
                className="bird-icon"
              />
              <div className="header-text mb-3">
                HEY JLOVERS! YOU HEARD IT. YOU SAW IT.
                <br />
                LET’S LIVE IT!
              </div>
              <div className="subheader-text">
                NOW … I WANT TO HEAR YOUR STORY AND I WILL BE REACTING TO AND FEATURING SOME OF MY FAVES RIGHT HERE ON
                THE JLO!
              </div>
              <ChimneyEmbed campaignId="b17dd668-45c9-675a-7b02-3a0f062f00f3" />
            </Col>
          </Row>
        </section>

        <section className="comments-container">
          <Row className="text-center mb-5">
            <Col xs={12} className="fade-in">
              <img
                src="https://1770075938.rsc.cdn77.org/onthejlo/cant-get-enough-landing/images/bird.svg"
                alt="Bird Icon"
                className="bird-icon"
              />
              <p className="comments-header header-text mb-2">Read some of the recent comments</p>
              <h2 className="heading mb-3">FROM OUR JLOVERS!</h2>
              {comments.map((comment) => (
                <Comment comment={comment} />
              ))}
            </Col>
          </Row>
        </section>

        <section className="homepage-video-container">
          <div
            className="iframe-video-container"
            onClick={() =>
              GoogleAnalyticsEvent('event', 'VideoPlay', {
                event_label: 'Video - Homepage - Jennifer Lopez - Rebound',
                send_to: STORM_GA_PROPERTY_ID,
              })
            }
          >
            <iframe
              width="1000"
              height="562"
              src="https://www.youtube.com/embed/Orp7aj1w8Eg?si=frfF5phYGfch1bC1"
              title="Jennifer Lopez - Rebound (Official Music Video)"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
        </section>

        <section className="homepage-video-container">
          <div
            className="iframe-video-container"
            onClick={() =>
              GoogleAnalyticsEvent('event', 'VideoPlay', {
                event_label:
                  "Video - Homepage - Jennifer Lopez - Can't Get Enough (feat. Latto) [Official Music Video]",
                send_to: STORM_GA_PROPERTY_ID,
              })
            }
          >
            <iframe
              width="1000"
              height="562"
              src="https://www.youtube.com/embed/vC52e4yloms?si=9ITZ-hWfY6hziuGq"
              title="Jennifer Lopez - Can't Get Enough (feat. Latto) [Official Music Video]"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
        </section>

        <section className="homepage-video-container">
          <div
            className="iframe-video-container"
            onClick={() =>
              GoogleAnalyticsEvent('event', 'VideoPlay', {
                event_label:
                  'Video - Homepage - Jennifer Lopez - Amazon @PrimeVideo Original This Is Me...Now: A Love Story',
                send_to: STORM_GA_PROPERTY_ID,
              })
            }
          >
            <iframe
              width="1000"
              height="562"
              src="https://www.youtube.com/embed/XN4-DzB9RGk?si=EmocbkOTRF9ov2g0"
              title="Jennifer Lopez - Amazon @PrimeVideo Original This Is Me...Now: A Love Story - Official Trailer"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
        </section>

        <section className="main-content">
          {/* <Row className="text-center mb-4">
            <Col xs={12} className="mt-2 mt-md-4">
              <h1 className="heading text-center bold">THIS IS ME…NOW</h1>
              <h1 className="heading text-center bold mb-0">
                HEAR IT. SEE IT. LIVE&nbsp;IT…SHARE&nbsp;IT…
                <br />
              </h1>
              <p className="header-text mt-3 mb-0">The Musical Experience Begins 2.16.24</p>
            </Col>
          </Row> */}

          <Row className="text-center mb-4"></Row>

          <Row className="text-center mb-4">
            <Col xs={12} className="fade-in">
              <img
                src="https://1770075938.rsc.cdn77.org/onthejlo/cant-get-enough-landing/images/bird.svg"
                alt="Bird Icon"
                className="bird-icon"
              />

              <h2 className="heading">Stream...Now</h2>
            </Col>

            <Col xs={12}>
              <img
                src="https://1770075938.rsc.cdn77.org/onthejlo/cant-get-enough-landing/images/album-cover.webp"
                alt="Jennifer Lopez - Can't Get Enough"
                className="fade-in album-cover mb-4"
              />
              <a
                className="button-link"
                href="https://jenniferlopez.lnk.to/CantGetEnoughWE"
                target="_blank"
                rel="noreferrer"
                onClick={() => GoogleAnalyticsEvent('event', 'StreamButtonClick', { send_to: STORM_GA_PROPERTY_ID })}
              >
                LISTEN TO CAN’T GET ENOUGH
              </a>
            </Col>
          </Row>

          <Row className="text-center mb-5">
            <Col xs={12}>
              <img
                src="https://1770075938.rsc.cdn77.org/onthejlo/cant-get-enough-landing/images/this-is-me-now-cover.webp"
                alt="Jennifer Lopez - This Is Me...Now"
                className="fade-in album-cover mb-4"
              />
              <a
                className="button-link"
                href="https://jenniferlopez.lnk.to/ThisIsMeNowWE"
                target="_blank"
                rel="noreferrer"
                onClick={() => GoogleAnalyticsEvent('event', 'StreamButtonClick', { send_to: STORM_GA_PROPERTY_ID })}
              >
                LISTEN…NOW
              </a>
            </Col>
          </Row>

          <Row className="text-center mb-5">
            <Col xs={12} className="fade-in">
              <img
                src="https://1770075938.rsc.cdn77.org/onthejlo/cant-get-enough-landing/images/bird.svg"
                alt="Bird Icon"
                className="bird-icon"
              />
              <h2 className="heading">Shop...Now</h2>
            </Col>

            <Col xs={12} className="fade-in mb-4">
              <a
                href="https://store.jenniferlopez.com/"
                target="_blank"
                rel="noreferrer"
                onClick={() => GoogleAnalyticsEvent('event', 'StoreButtonClick', { send_to: STORM_GA_PROPERTY_ID })}
              >
                <img
                  src="https://1770075938.rsc.cdn77.org/assets/merch-squared.webp"
                  alt="Shop This Is Me...Now"
                  className="fade-in album-cover mb-4"
                />
              </a>
            </Col>

            <Col xs={12} className="">
              <a
                className="button-link"
                href="https://store.jenniferlopez.com/"
                target="_blank"
                rel="noreferrer"
                onClick={() => GoogleAnalyticsEvent('event', 'StoreButtonClick', { send_to: STORM_GA_PROPERTY_ID })}
              >
                The...Now Collection
              </a>
            </Col>
          </Row>

          <Row className="text-center mb-5"></Row>
        </section>

        <Footer />
      </Container>
    </div>
  );
};

export default CGELandingPage;
