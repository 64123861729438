import React, { useEffect } from 'react';

const SEATED_ID = 'db2f0361-8785-495d-9e4f-a09e168bfa51';

const SeatedWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://widget.seated.com/app.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="seated-widget-container">
      <div className="seated-widget-description mt-4">
        {/* <p>
          Advance presale registration is available now. Please register for your show of choice below. Artist Presale
          begins on Tuesday, February 20th at 9:00 AM local venue time.
        </p>
        <p>Advance registration does not guarantee tickets. Supplies are limited.</p> */}
      </div>
      <div id="seated-55fdf2c0" data-artist-id={SEATED_ID} data-css-version="3"></div>
    </div>
  );
};

export default SeatedWidget;
