import React, { useEffect, useRef } from 'react';

interface ChimneyEmbedProps {
  campaignId: string;
}

const ChimneyEmbed: React.FC<ChimneyEmbedProps> = ({ campaignId }) => {
  const chimneyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.usechimney.com/sdk/js/1.45.0/dist/chimney.min.js';

    if (chimneyRef.current) {
      chimneyRef.current.setAttribute('data-campaign-id', campaignId);
      chimneyRef.current.appendChild(script);
    }

    return () => {
      if (chimneyRef.current) {
        chimneyRef.current.removeAttribute('data-campaign-id');
        chimneyRef.current.removeChild(script);
      }
    };
  }, [campaignId]);

  return (
    <div className="landing-embed-form">
      <div className="chimney" ref={chimneyRef}></div>
    </div>
  );
};

export default ChimneyEmbed;
