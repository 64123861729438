import { DependencyList, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const additionalScriptId = 'fc';
const scriptId = 'TrackingScript';
const scriptContent = `
(function (w, d, s, o, f, js, fjs) {
  w['FCObject'] = o; w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
  js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
  js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
}(window, document, 'script', 'fc', 'https://tags.fullcontact.com/anon/fullcontact.js'));
fc('init',"5B4PqksgKFyWpEZ5zP5N7yPwfjq7QHJB");
`;

function useScriptTracking(dependencyList: DependencyList = []) {
  const location = useLocation();

  // TEMPORARILY DISABLED WHILE EMBEDDED IN OTHER SITE
  // useEffect(() => {
  //   const script = document.getElementById(additionalScriptId);
  //   if (script) {
  //     script.remove();
  //   }
  // }, [location]);

  // useEffect(() => {
  //   const script = document.getElementById(scriptId);
  //   if (script) {
  //     script.remove();
  //   }

  //   const newScript = document.createElement('script');
  //   newScript.id = scriptId;
  //   newScript.innerHTML = scriptContent;
  //   document.head.appendChild(newScript);
  // }, dependencyList);
}

export default useScriptTracking;
