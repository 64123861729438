import { Container, Col, Row } from 'react-bootstrap';
import SignUpForm from 'components/shared/SignUpForm';
import SuccessPage from './SuccessPage';
import React from 'react';
import Arrow from '../shared/Arrow';
import LogoLetters from 'components/shared/LogoLetters';
import LogoHeader from 'components/shared/LogoHeader';
import usePageView from '../../hooks/usePageView';
import Footer from 'components/Footer';
import { SuccessPageRoute, SuccessPageTitle, SignUpPageTitle } from '../../constants/pageRoutes';
import useScriptTracking from 'hooks/useScriptTracking';

import '../../styles/cge-landing-style.scss';
import Header from 'components/shared/Header';

interface ILandingPageProps {
  success?: boolean;
}

const getPageDetails: (success: boolean) => { pageTitle: string; pagePath: string } = (success) =>
  success
    ? { pageTitle: SuccessPageTitle, pagePath: SuccessPageRoute }
    : { pageTitle: SignUpPageTitle, pagePath: SignUpPageTitle };

const SignUpPage: React.FC<ILandingPageProps> = ({ success }) => {
  const pageDetails = getPageDetails(success || false);
  usePageView(pageDetails.pageTitle, pageDetails.pagePath, [success]);
  useScriptTracking([success]);

  return (
    <div className="body-content">
      <div className="jlo-logo-wrapper">
        <div className="jlo-logo-container">
          <a href="/">
            <div className="d-flex gap-2 align-items-end justify-content-center">
              <div className="">
                <LogoHeader />
                <LogoLetters />
              </div>
              <Arrow />
            </div>
          </a>
        </div>
      </div>

      <Container>
        <div style={{ paddingTop: '60px' }} />
        <a id="join_now"></a>
        <section className="main-content mb-10">
          <Row className="mb-2 text-center">
            <Col xs={12}>
              <img
                src="https://1770075938.rsc.cdn77.org/onthejlo/cant-get-enough-landing/images/bird.svg"
                alt="Bird Icon"
                className="bird-icon"
              />
              <h1 className="heading text-center">Join...Now</h1>
              <p className="header-text">
                Join On the JLo to get access to news, special experiences, exclusive content and more.
                <br />
                <span className="subheader-text">(Just keep it On the JLo)</span>
              </p>
            </Col>
          </Row>

          <Row className="form-wrapper mb-4 mb-sm-5">
            <Col xs={12} className="fade-in">
              {!success && <SignUpForm parentPage={SignUpPageTitle} />}
              {success && <SuccessPage />}
            </Col>
          </Row>
        </section>

        <Footer />
      </Container>
    </div>
  );
};

export default SignUpPage;
