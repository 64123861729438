import Footer from 'components/Footer';
import Arrow from 'components/shared/Arrow';
import LogoHeader from 'components/shared/LogoHeader';
import LogoLetters from 'components/shared/LogoLetters';
import { UnsubscribePageTitle } from 'constants/pageRoutes';
import { Container, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import UnsubscribeForm from 'components/shared/UnsubscribeForm';
import { unsubscribeLocalStorage } from 'helpers/LocalStorage';
import UnsubscribeSuccess from 'components/UnsubscribeSuccess';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const UnsubscribePage: React.FC = () => {
  const [unsubscribeSuccess, setUnsubscribeSuccess] = useState<boolean>(false);
  const [initialEmail, setEmail] = useState<string>('');

  const query = useQuery();

  useEffect(() => {
    const emailParam = query.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }
  }, [query]);

  return (
    <div className="body-content">
      <div className="jlo-logo-wrapper">
        <div className="jlo-logo-container">
          <a href="/">
            <div className="d-flex gap-2 align-items-end justify-content-center">
              <div className="">
                <LogoHeader />
                <LogoLetters />
              </div>
              <Arrow />
            </div>
          </a>
        </div>
      </div>

      <Container>
        <div style={{ paddingTop: '60px' }} />
        <section className="main-content mb-10">
          <Row className="mb-2 text-center">
            <Col xs={12}>
              <img
                src="https://1770075938.rsc.cdn77.org/onthejlo/cant-get-enough-landing/images/bird.svg"
                alt="Bird Icon"
                className="bird-icon"
              />
              <h1 className="heading text-center">Unsubscribe</h1>
            </Col>
          </Row>

          <Row className="form-wrapper mb-4 mb-sm-5">
            <Col xs={12} className="fade-in">
              {!unsubscribeSuccess && (
                <UnsubscribeForm
                  parentPage={UnsubscribePageTitle}
                  initialEmailAddress={initialEmail}
                  onSuccess={() => {
                    setUnsubscribeSuccess(true);
                    unsubscribeLocalStorage();
                  }}
                />
              )}
              {unsubscribeSuccess && <UnsubscribeSuccess />}
            </Col>
          </Row>
        </section>

        <Footer />
      </Container>
    </div>
  );
};

export default UnsubscribePage;
