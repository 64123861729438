const isSubscriberKey = 'OnTheJloSubscriber';

const setValue = (value: string, key: string) => {
  try {
    window.localStorage.setItem(key, value);
  } catch {
    // localStorage not available
  }
};

const deleteValue = (key: string) => {
  try {
    window.localStorage.removeItem(key);
  } catch {
    // localStorage not available
  }
};

const getValue = (key: string) => {
  try {
    const value = window.localStorage.getItem(key);
    return value;
  } catch {
    return null;
  }
};

export const setIsSubscriberLocalStorage = () => setValue('Subscribed', isSubscriberKey);

export const unsubscribeLocalStorage = () => deleteValue(isSubscriberKey);

export const getIsSubscriberLocalStorage = () => {
  const value = getValue(isSubscriberKey);
  return Boolean(value);
};
