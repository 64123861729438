export const VideoUrlNameKey = 'videoShortCode';
export const VideoUrlNameKeyWithColon = `:${VideoUrlNameKey}`;

export const LandingPageRoute = '/';
export const LandingPageTitle = 'Landing Page';

export const SuccessPageRoute = '/success';
export const SuccessPageTitle = 'Success Page';

export const SignUpPageRoute = '/sign-up';
export const SignUpPageTitle = 'Sign Up Page';

export const VideoPageRoute = `'/video/:${VideoUrlNameKey}'`;

export const PrivacyPageRoute = '/privacy';
export const PrivacyPageTitle = 'Privacy Page';

export const UnsubscribePageRoute = '/unsubscribe';
export const UnsubscribePageTitle = 'Unsubscribe Page';
