import LogoO from '../../images/logo-o.png';
import LogoJ from '../../images/logo-j.png';
import LogoL from '../../images/logo-l.png';

const LogoLetters: React.FC = () => (
  <div className="logo-letter-container">
    <img src={LogoJ} alt="" className="img-fluid logo-letter" />
    <img src={LogoL} alt="" className="img-fluid logo-letter" />
    <img src={LogoO} alt="" className="img-fluid logo-letter" />
  </div>
);

export default LogoLetters;
