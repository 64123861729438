import { Col, Container, Row } from 'react-bootstrap';
import usePageView from '../../hooks/usePageView';
import { PrivacyPageTitle, PrivacyPageRoute } from '../../constants/pageRoutes';
import useScriptTracking from 'hooks/useScriptTracking';

const PrivacyPage: React.FC = () => {
  usePageView(PrivacyPageTitle, PrivacyPageRoute);
  useScriptTracking();
  return (
    <div className="w-100">
      <div className="logo-wrapper">
        <div className="logo">
          <a href="/">
            <img src="https://1770075938.rsc.cdn77.org/onthejlo/logo.png" alt="on-the-jlo-logo" className="img-fluid" />
          </a>
        </div>
      </div>
      <Container className="py-3 py-md-5 privacy-content mx-auto">
        <Row>
          <Col xs={12}>
            <h1>PRIVACY NOTICE</h1>
            <strong>Last updated March 21, 2023.</strong>
            <p>
              This privacy notice for Nuyorican Productions ("<strong>Company</strong>," "<strong>we</strong>," "
              <strong>us</strong>," or "<strong>our</strong>"), describes how and why we might collect, store, use,
              and/or share ("process") your information when you use our services ("Services"), such as when you:
            </p>
            <ul className="list-revert">
              <li>
                Visit our website at <a href="https://web.onthejlo.com/">https://web.onthejlo.com</a>, or any website of
                ours that links to this privacy notice
              </li>
              <li>Engage with us in other related ways, including any sales, marketing, or events</li>
            </ul>
            <h3>Questions or concerns?</h3>
            <p>
              Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree
              with our policies and practices, please do not use our Services. If you still have any questions or
              concerns, please contact us at <a href="mailto:onthejloprivacy@gmail.com">onthejloprivacy@gmail.com</a>.
            </p>
            <h2>SUMMARY OF KEY POINTS</h2>
            <p>
              This summary provides key points from our privacy notice, but you can find out more details about any of
              these topics by clicking the link following each key point or by using our table of contents below to find
              the section you are looking for. You can also <a href="#tableOfContents">click here</a> to go directly to
              our table of contents.
            </p>
            <h3>What personal information do we process?</h3>
            <p>
              When you visit, use, or navigate our Services, we may process personal information depending on how you
              interact with Nuyorican Productions and the Services, the choices you make, and the products and features
              you use. <a href="#item1">Click here</a> to learn more.
            </p>
            <h3>Do we process any sensitive personal information?</h3>
            <p>We do not process sensitive personal information.</p>
            <h3>Do we receive any information from third parties?</h3>
            <p>We do not receive any information from third parties.</p>
            <h3>How do we process your information?</h3>
            <p>
              We process your information to provide, improve, and administer our Services, communicate with you, for
              security and fraud prevention, and to comply with law. We may also process your information for other
              purposes with your consent. We process your information only when we have a valid legal reason to do so.
              <a href="#item3">Click here</a> to learn more.
            </p>
            <h3>In what situations and with which parties do we share personal information?</h3>
            <p>
              We may share information in specific situations and with specific third parties.{' '}
              <a href="#item4">Click here</a> to learn more.
            </p>
            <h3>How do we keep your information safe?</h3>
            <p>
              We have organizational and technical processes and procedures in place to protect your personal
              information. However, no electronic transmission over the internet or information storage technology can
              be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other
              unauthorized third parties will not be able to defeat our security and improperly collect, access, steal,
              or modify your information. <a href="#item6">Click here</a> to learn more.
            </p>
            <h3>What are your rights?</h3>
            <p>
              Depending on where you are located geographically, the applicable privacy law may mean you have certain
              rights regarding your personal information. <a href="#item9">Click here</a> to learn more.
            </p>
            <h3>How do you exercise your rights?</h3>
            <p>
              The easiest way to exercise your rights is by contacting us. We will consider and act upon any request in
              accordance with applicable data protection laws.
            </p>
            <h3>Want to learn more about what Nuyorican Productions does with any information we collect?</h3>
            <p>
              <a href="#item1">Click here</a> to review the notice in full.
            </p>

            <h2 id="tableOfContents">TABLE OF CONTENTS</h2>
            <ol>
              <li>
                <a href="#item1">WHAT INFORMATION DO WE COLLECT?</a>
              </li>
              <li>
                <a href="#item2">HOW DO WE PROCESS YOUR INFORMATION?</a>
              </li>
              <li>
                <a href="#item3">WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</a>
              </li>
              <li>
                <a href="#item4">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>
              </li>
              <li>
                <a href="#item5">IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</a>
              </li>
              <li>
                <a href="#item6">HOW LONG DO WE KEEP YOUR INFORMATION?</a>
              </li>
              <li>
                <a href="#item7">HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
              </li>
              <li>
                <a href="#item8">DO WE COLLECT INFORMATION FROM MINORS?</a>
              </li>
              <li>
                <a href="#item9">WHAT ARE YOUR PRIVACY RIGHTS?</a>
              </li>
              <li>
                <a href="#item10">CONTROLS FOR DO-NOT-TRACK FEATURES</a>
              </li>
              <li>
                <a href="#item11">DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a>
              </li>
              <li>
                <a href="#item12">DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a>
              </li>
              <li>
                <a href="#item13">DO WE MAKE UPDATES TO THIS NOTICE?</a>
              </li>
              <li>
                <a href="#item14">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
              </li>
              <li>
                <a href="#item15">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a>
              </li>
            </ol>
            <ol className="toc-content">
              <li id="item1">
                <h2>WHAT INFORMATION DO WE COLLECT?</h2>
                <h3>Personal information you disclose to us</h3>
                <p>
                  <strong>In Short: </strong>
                  <i>We collect personal information that you provide to us.</i>
                </p>
                <p>
                  We collect personal information that you voluntarily provide to us when you express an interest in
                  obtaining information about us or our products and Services, when you participate in activities on the
                  Services, or otherwise when you contact us.
                </p>
                <p>
                  <strong>Personal Information Provided by You.</strong> The personal information that we collect
                  depends on the context of your interactions with us and the Services, the choices you make, and the
                  products and features you use. The personal information we collect may include the following:
                </p>
                <ul className="list-revert">
                  <li>email addresses </li>
                </ul>
                <p>
                  <strong>Sensitive Information</strong>. We do not process sensitive information.
                </p>
                <p>
                  All personal information that you provide to us must be true, complete, and accurate, and you must
                  notify us of any changes to such personal information.
                </p>
                <h3>Information automatically collected</h3>
                <p>
                  <i>
                    In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device
                    characteristics — is collected automatically when you visit our Services.
                  </i>
                </p>
                <p>We automatically collect certain information when you visit, use, or navigate the Services.</p>
                <p>
                  This information does not reveal your specific identity (like your name or contact information) but
                  may include device and usage information, such as your IP address, browser and device characteristics,
                  operating system, language preferences, referring URLs, device name, country, location, information
                  about how and when you use our Services, and other technical information. This information is
                  primarily needed to maintain the security and operation of our Services, and for our internal
                  analytics and reporting purposes.
                </p>
                <p>The information we collect includes:</p>
                <ul className="list-revert">
                  <li>
                    Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance
                    information our servers automatically collect when you access or use our Services and which we
                    record in log files. Depending on how you interact with us, this log data may include your IP
                    address, device information, browser type, and settings and information about your activity in the
                    Services (such as the date/time stamps associated with your usage, pages and files viewed, searches,
                    and other actions you take such as which features you use), device event information (such as system
                    activity, error reports (sometimes called "crash dumps"), and hardware settings).
                  </li>
                </ul>
              </li>
              <li id="item2">
                <h2>HOW DO WE PROCESS YOUR INFORMATION?</h2>
                <p>
                  <i>
                    In Short: We process your information to provide, improve, and administer our Services, communicate
                    with you, for security and fraud prevention, and to comply with law. We may also process your
                    information for other purposes with your consent.
                  </i>
                </p>
                <p>
                  We process your personal information for a variety of reasons, depending on how you interact with our
                  Services, including:
                </p>
                <ul className="list-revert">
                  <li>
                    To deliver and facilitate delivery of services to the user. We may process your information to
                    provide you with the requested service.
                  </li>
                  <li>
                    To save or protect an individual's vital interest. We may process your information when necessary to
                    save or protect an individual’s vital interest, such as to prevent harm.
                  </li>
                </ul>
              </li>
              <li id="item3">
                <h2>WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>
                <p>
                  <i>
                    In Short: We only process your personal information when we believe it is necessary and we have a
                    valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to
                    comply with laws, to provide you with services to enter into or fulfill our contractual obligations,
                    to protect your rights, or to fulfill our legitimate business interests.
                  </i>
                </p>
                <h3>If you are located in the EU or UK, this section applies to you.</h3>
                <p>
                  The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases
                  we rely on in order to process your personal information. As such, we may rely on the following legal
                  bases to process your personal information:
                </p>
                <ul className="list-revert">
                  <li>
                    Consent. We may process your information if you have given us permission (i.e., consent) to use your
                    personal information for a specific purpose. You can withdraw your consent at any time.
                    <a href="#item9">Click here</a> to learn more.
                  </li>
                  <li>
                    Performance of a Contract. We may process your personal information when we believe it is necessary
                    to fulfill our contractual obligations to you, including providing our Services or at your request
                    prior to entering into a contract with you.
                  </li>
                  <li>
                    Legal Obligations. We may process your information where we believe it is necessary for compliance
                    with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency,
                    exercise or defend our legal rights, or disclose your information as evidence in litigation in which
                    we are involved.
                  </li>
                  <li>
                    Vital Interests. We may process your information where we believe it is necessary to protect your
                    vital interests or the vital interests of a third party, such as situations involving potential
                    threats to the safety of any person.
                  </li>
                </ul>
                <h3>If you are located in Canada, this section applies to you.</h3>
                <p>
                  We may process your information if you have given us specific permission (i.e., express consent) to
                  use your personal information for a specific purpose, or in situations where your permission can be
                  inferred (i.e., implied consent). You can withdraw your consent at any time.{' '}
                  <a href="#item9">Click here</a> to learn more.
                </p>
                <p>
                  In some exceptional cases, we may be legally permitted under applicable law to process your
                  information without your consent, including, for example:
                </p>
                <ul className="list-revert">
                  <li>
                    If collection is clearly in the interests of an individual and consent cannot be obtained in a
                    timely way
                  </li>
                  <li>For investigations and fraud detection and prevention</li>
                  <li>For business transactions provided certain conditions are met</li>
                  <li>
                    If it is contained in a witness statement and the collection is necessary to assess, process, or
                    settle an insurance claim
                  </li>
                  <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
                  <li>
                    If we have reasonable grounds to believe an individual has been, is, or may be victim of financial
                    abuse
                  </li>
                  <li>
                    If it is reasonable to expect collection and use with consent would compromise the availability or
                    the accuracy of the information and the collection is reasonable for purposes related to
                    investigating a breach of an agreement or a contravention of the laws of Canada or a province
                  </li>
                  <li>
                    If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court
                    relating to the production of records
                  </li>
                  <li>
                    If it was produced by an individual in the course of their employment, business, or profession and
                    the collection is consistent with the purposes for which the information was produced
                  </li>
                  <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
                  <li>If the information is publicly available and is specified by the regulations</li>
                </ul>
              </li>
              <li id="item4">
                <h2>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
                <p>
                  In Short: We may share information in specific situations described in this section and/or with the
                  following third parties.
                </p>
                <p>We may need to share your personal information in the following situations:</p>
                <ul className="list-revert">
                  <li>
                    Business Transfers. We may share or transfer your information in connection with, or during
                    negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion
                    of our business to another company.
                  </li>
                </ul>
              </li>
              <li id="item5">
                <h2> IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h2>
                <p>
                  Our servers are located in the United States. If you are accessing our Services from outside the
                  United States, please be aware that your information may be transferred to, stored, and processed by
                  us in our facilities and by those third parties with whom we may share your personal information (see
                  "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?" above), in the United States and other
                  countries.
                </p>
                <p>
                  If you are a resident in the European Economic Area (EEA) or United Kingdom (UK), then these countries
                  may not necessarily have data protection laws or other similar laws as comprehensive as those in your
                  country. However, we will take all necessary measures to protect your personal information in
                  accordance with this privacy notice and applicable law.
                </p>
                <h3>European Commission's Standard Contractual Clauses:</h3>
                <p>
                  We have implemented measures to protect your personal information, including by using the European
                  Commission's Standard Contractual Clauses for transfers of personal information between our group
                  companies and between us and our third-party providers. These clauses require all recipients to
                  protect all personal information that they process originating from the EEA or UK in accordance with
                  European data protection laws and regulations. Our Standard Contractual Clauses can be provided upon
                  request. We have implemented similar appropriate safeguards with our third-party service providers and
                  partners and further details can be provided upon request.
                </p>
              </li>
              <li id="item6">
                <h2>HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
                <p>
                  <i>
                    In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this
                    privacy notice unless otherwise required by law.
                  </i>
                </p>
                <p>
                  We will only keep your personal information for as long as it is necessary for the purposes set out in
                  this privacy notice, unless a longer retention period is required or permitted by law (such as tax,
                  accounting, or other legal requirements).When we have no ongoing legitimate business need to process
                  your personal information, we will either delete or anonymize such information, or, if this is not
                  possible (for example, because your personal information has been stored in backup archives), then we
                  will securely store your personal information and isolate it from any further processing until
                  deletion is possible.
                </p>
              </li>
              <li id="item7">
                <h2>HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
                <p>
                  <i>
                    In Short: We aim to protect your personal information through a system of organizational and
                    technical security measures.
                  </i>
                </p>
                <p>
                  We have implemented appropriate and reasonable technical and organizational security measures designed
                  to protect the security of any personal information we process. However, despite our safeguards and
                  efforts to secure your information, no electronic transmission over the Internet or information
                  storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that
                  hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security
                  and improperly collect, access, steal, or modify your information. Although we will do our best to
                  protect your personal information, transmission of personal information to and from our Services is at
                  your own risk. You should only access the Services within a secure environment.
                </p>
              </li>
              <li id="item8">
                <h2>DO WE COLLECT INFORMATION FROM MINORS? </h2>
                <p>
                  <i>In Short: We do not knowingly collect data from or market to children under 18 years of age.</i>
                </p>
                <p>
                  We do not knowingly solicit data from or market to children under 18 years of age. By using the
                  Services, you represent that you are at least 18 or that you are the parent or guardian of such a
                  minor and consent to such minor dependent’s use of the Services. If we learn that personal information
                  from users less than 18 years of age has been collected, we will deactivate the account and take
                  reasonable measures to promptly delete such data from our records. If you become aware of any data we
                  may have collected from children under age 18, please contact us at{' '}
                  <a href="mailto:onthejloprivacy@gmail.com">onthejloprivacy@gmail.com</a>.
                </p>
              </li>
              <li id="item9">
                <h2>WHAT ARE YOUR PRIVACY RIGHTS?</h2>
                <p>
                  <i>
                    In Short: In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and
                    Canada, you have rights that allow you greater access to and control over your personal
                    information. You may review, change, or terminate your account at any time.
                  </i>
                </p>
                <p>
                  In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data
                  protection laws. These may include the right (i) to request access and obtain a copy of your personal
                  information, (ii) to request rectification or erasure; (iii) to restrict the processing of your
                  personal information; and (iv) if applicable, to data portability. In certain circumstances, you may
                  also have the right to object to the processing of your personal information. You can make such a
                  request by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US
                  ABOUT THIS NOTICE?" below.
                </p>
                <p>
                  We will consider and act upon any request in accordance with applicable data protection laws. If you
                  are located in the EEA or UK and you believe we are unlawfully processing your personal information,
                  you also have the right to complain to your local data protection supervisory authority. You can find
                  their contact details here:
                  <a
                    href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                  </a>
                  . If you are located in Switzerland, the contact details for the data protection authorities are
                  available here:{' '}
                  <a href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank" rel="noreferrer noopener">
                    https://www.edoeb.admin.ch/edoeb/en/home.html
                  </a>
                  .
                </p>
                <p>
                  <strong>Withdrawing your consent:</strong> If we are relying on your consent to process your personal
                  information, which may be express and/or implied consent depending on the applicable law, you have the
                  right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us
                  by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                  below.
                </p>
                <p>
                  However, please note that this will not affect the lawfulness of the processing before its withdrawal
                  nor, when applicable law allows, will it affect the processing of your personal information conducted
                  in reliance on lawful processing grounds other than consent.
                </p>
                <p>
                  <strong>Opting out of marketing and promotional communications:</strong> You can unsubscribe from our
                  marketing and promotional communications at any time by clicking on the unsubscribe link in the emails
                  that we send, or by contacting us using the details provided in the section "HOW CAN YOU CONTACT US
                  ABOUT THIS NOTICE?" below. You will then be removed from the marketing lists. However, we may still
                  communicate with you — for example, to send you service-related messages that are necessary for the
                  administration and use of your account, to respond to service requests, or for other non-marketing
                  purposes.
                </p>
                <p>
                  If you have questions or comments about your privacy rights, you may email us at{' '}
                  <a href="mailto:onthejloprivacy@gmail.com">onthejloprivacy@gmail.com</a>.
                </p>
              </li>
              <li id="item10">
                <h2>CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
                <p>
                  Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
                  ("DNT") feature or setting you can activate to signal your privacy preference not to have data about
                  your online browsing activities monitored and collected. At this stage no uniform technology standard
                  for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond
                  to DNT browser signals or any other mechanism that automatically communicates your choice not to be
                  tracked online. If a standard for online tracking is adopted that we must follow in the future, we
                  will inform you about that practice in a revised version of this privacy notice.
                </p>
              </li>
              <li id="item11">
                <h2>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
                <p>
                  <i>
                    In Short: Yes, if you are a resident of California, you are granted specific rights regarding access
                    to your personal information.
                  </i>
                </p>
                <p>
                  California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who
                  are California residents to request and obtain from us, once a year and free of charge, information
                  about categories of personal information (if any) we disclosed to third parties for direct marketing
                  purposes and the names and addresses of all third parties with which we shared personal information in
                  the immediately preceding calendar year. If you are a California resident and would like to make such
                  a request, please submit your request in writing to us using the contact information provided below.
                </p>
                <p>
                  If you are under 18 years of age, reside in California, and have a registered account with Services,
                  you have the right to request removal of unwanted data that you publicly post on the Services. To
                  request removal of such data, please contact us using the contact information provided below and
                  include the email address associated with your account and a statement that you reside in California.
                  We will make sure the data is not publicly displayed on the Services, but please be aware that the
                  data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).
                </p>
                <h3>CCPA Privacy Notic</h3>
                <p>
                  The California Code of Regulations defines a "resident" as: (1) every individual who is in the State
                  of California for other than a temporary or transitory purpose and (2) every individual who is
                  domiciled in the State of California who is outside the State of California for a temporary or
                  transitory purpose
                </p>
                <p>All other individuals are defined as "non-residents."</p>
                <p>
                  If this definition of "resident" applies to you, we must adhere to certain rights and obligations
                  regarding your personal information.
                </p>
                <h3>What categories of personal information do we collect? </h3>
                <p>
                  We have collected the following categories of personal information in the past twelve (12) months:
                </p>
                <p>Email Address - YES</p>
                <h3>
                  We will use and retain the collected personal information as needed to provide the Services or for:
                </h3>
                <ul className="list-revert">
                  <li>As long as the user has an account with us</li>
                </ul>
                <p>
                  We may also collect other personal information outside of these categories through instances where you
                  interact with us in person, online, or by phone or mail in the context of:
                </p>
                <ul className="list-revert">
                  <li>Receiving help through our customer support channels;</li>
                  <li>Participation in customer surveys or contests; and</li>
                  <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
                </ul>
                <p>
                  <strong>How do we use and share your personal information? </strong>
                </p>
                <p>
                  More information about our data collection and sharing practices can be found in this privacy notice.
                </p>
                <p>You may contact us Mailing Address:</p>
                <p>
                  Nuyorican Productions, 9200 W. Sunset Blvd. #820, Los Angeles, CA 90069, or by referring to the
                  contact details at the bottom of this document.
                </p>
                <p>
                  If you are using an authorized agent to exercise your right to opt out we may deny a request if the
                  authorized agent does not submit proof that they have been validly authorized to act on your behalf.
                </p>
                <h3>Will your information be shared with anyone else?</h3>
                <p>
                  We may disclose your personal information with our service providers pursuant to a written contract
                  between us and each service provider. Each service provider is a for-profit entity that processes the
                  information on our behalf, following the same strict privacy protection obligations mandated by the
                  CCPA.
                </p>
                <p>
                  We may use your personal information for our own business purposes, such as for undertaking internal
                  research for technological development and demonstration. This is not considered to be "selling" of
                  your personal information.
                </p>
                <p>
                  Nuyorican Productions has not disclosed, sold, or shared any personal information to third parties for
                  a business or commercial purpose in the preceding twelve (12) months. Nuyorican Productions will not
                  sell or share personal information in the future belonging to website visitors, users, and other
                  consumers.
                </p>
                <h3>Your rights with respect to your personal data</h3>
                <h3>Right to request deletion of the data — Request to delete</h3>
                <p>
                  You can ask for the deletion of your personal information. If you ask us to delete your personal
                  information, we will respect your request and delete your personal information, subject to certain
                  exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or
                  her right to free speech, our compliance requirements resulting from a legal obligation, or any
                  processing that may be required to protect against illegal activities.
                </p>
                <h3>Right to be informed — Request to know</h3>
                <p>Depending on the circumstances, you have a right to know:</p>
                <ul className="list-revert">
                  <li>whether we collect and use your personal information;</li>
                  <li>the categories of personal information that we collect;</li>
                  <li>the purposes for which the collected personal information is used;</li>
                  <li>whether we sell or share personal information to third parties;</li>
                  <li>
                    the categories of personal information that we sold, shared, or disclosed for a business purpose;
                  </li>
                  <li>
                    the categories of third parties to whom the personal information was sold, shared, or disclosed for
                    a business purpose;
                  </li>
                  <li>
                    the business or commercial purpose for collecting, selling, or sharing personal information; and
                  </li>
                  <li>
                    the specific pieces of personal information we collected about you.In accordance with applicable
                    law, we are not obligated to provide or delete consumer information that is de-identified in
                    response to a consumer request or to re-identify individual data to verify a consumer request.
                  </li>
                </ul>
                <h3>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</h3>
                <p>We will not discriminate against you if you exercise your privacy rights.</p>
                <h3>Right to Limit Use and Disclosure of Sensitive Personal Information</h3>
                <p>We do not process consumer's sensitive personal information.</p>
                <h3>Verification process</h3>
                <p>
                  Upon receiving your request, we will need to verify your identity to determine you are the same person
                  about whom we have the information in our system. These verification efforts require us to ask you to
                  provide information so that we can match it with information you have previously provided us. For
                  instance, depending on the type of request you submit, we may ask you to provide certain information
                  so that we can match the information you provide with the information we already have on file, or we
                  may contact you through a communication method (e.g., phone or email) that you have previously
                  provided to us. We may also use other verification methods as the circumstances dictate.
                </p>
                <p>
                  We will only use personal information provided in your request to verify your identity or authority to
                  make the request. To the extent possible, we will avoid requesting additional information from you for
                  the purposes of verification. However, if we cannot verify your identity from the information already
                  maintained by us, we may request that you provide additional information for the purposes of verifying
                  your identity and for security or fraud-prevention purposes. We will delete such additionally provided
                  information as soon as we finish verifying you.
                </p>
                <h3>Other privacy rights</h3>
                <ul className="list-revert">
                  <li>You may object to the processing of your personal information.</li>
                  <li>
                    You may request correction of your personal data if it is incorrect or no longer relevant, or ask to
                    restrict the processing of the information.
                  </li>
                  <li>
                    You can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a
                    request from an authorized agent that does not submit proof that they have been validly authorized
                    to act on your behalf in accordance with the CCPA.
                  </li>
                  <li>
                    You may request to opt out from future selling or sharing of your personal information to third
                    parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly
                    possible, but no later than fifteen (15) days from the date of the request submission.
                  </li>
                </ul>
                <p>To exercise these rights, you can contact us at the below Mailing Address:</p>
                <p>
                  Nuyorican Productions, 9200 W. Sunset Blvd. #820, Los Angeles, CA 90069, or by referring to the
                  contact details at the bottom of this document. If you have a complaint about how we handle your data,
                  we would like to hear from you.
                </p>
              </li>
              <li id="item12">
                <h2>DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
                <p>
                  <i>
                    In Short: Yes, if you are a resident of Virginia, you may be granted specific rights regarding
                    access to and use of your personal information.
                  </i>
                </p>
                <h3>Virginia CDPA Privacy Notice</h3>
                <p>
                  Under the Virginia Consumer Data Protection Act (CDPA):"Consumer" means a natural person who is a
                  resident of the Commonwealth acting only in an individual or household context. It does not include a
                  natural person acting in a commercial or employment context.
                </p>
                <p>
                  "Personal data" means any information that is linked or reasonably linkable to an identified or
                  identifiable natural person. "Personal data" does not include de-identified data or publicly available
                  information.
                </p>
                <p>"Sale of personal data" means the exchange of personal data for monetary consideration.</p>
                <p>
                  If this definition "consumer" applies to you, we must adhere to certain rights and obligations
                  regarding your personal data.
                </p>
                <p>
                  The information we collect, use, and disclose about you will vary depending on how you interact with
                  Nuyorican Productions and our Services. To find out more, please visit the following links:
                </p>
                <ul className="list-revert">
                  <li>Personal data we collect</li>
                  <li>How we use your personal data</li>
                  <li>When and with whom we share your personal data</li>
                </ul>
                <p>Your rights with respect to your personal data </p>
                <ul className="list-revert">
                  <li>Right to be informed whether or not we are processing your personal data</li>
                  <li>Right to access your personal data</li>
                  <li>Right to correct inaccuracies in your personal data</li>
                  <li>Right to request deletion of your personal data</li>
                  <li>Right to obtain a copy of the personal data you previously shared with us</li>
                  <li>
                    Right to opt out of the processing of your personal data if it is used for targeted advertising, the
                    sale of personal data, or profiling in furtherance of decisions that produce legal or similarly
                    significant effects ("profiling"). Nuyorican Productions has not sold any personal data to third
                    parties for business or commercial purposes. Nuyorican Productions will not sell personal data in
                    the future belonging to website visitors, users, and other consumers.
                  </li>
                </ul>
                <h3>Exercise your rights provided under the Virginia CDPA. </h3>
                <p>
                  More information about our data collection and sharing practices can be found in this privacy notice.
                </p>
                <p>
                  You may contact us by email at{' '}
                  <a href="mailto:onthejloprivacy@gmail.com">onthejloprivacy@gmail.com</a>, by visiting our data subject
                  request form, or by referring to the contact details at the bottom of this document.
                </p>
                <p>
                  If you are using an authorized agent to exercise your rights, we may deny a request if the authorized
                  agent does not submit proof that they have been validly authorized to act on your behalf.
                </p>
                <h3>Verification process</h3>
                <p>
                  We may request that you provide additional information reasonably necessary to verify you and your
                  consumer's request. If you submit the request through an authorized agent, we may need to collect
                  additional information to verify your identity before processing your request.
                </p>
                <p>
                  Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five
                  (45) days of receipt. The response period may be extended once by forty-five (45) additional days when
                  reasonably necessary. We will inform you of any such extension within the initial 45-day response
                  period, together with the reason for the extension.
                </p>
                <h3>Right to appeal</h3>
                <p>
                  If we decline to take action regarding your request, we will inform you of our decision and reasoning
                  behind it. If you wish to appeal our decision, please email us at{' '}
                  <a href="mailto:onthejloprivacy@gmail.com">onthejloprivacy@gmail.com</a>. Within sixty (60) days of
                  receipt of an appeal, we will inform you in writing of any action taken or not taken in response to
                  the appeal, including a written explanation of the reasons for the decisions. If your appeal if
                  denied, you may contact the Attorney General to submit a complaint.
                </p>
              </li>
              <li id="item13">
                <h2>DO WE MAKE UPDATES TO THIS NOTICE?</h2>
                <p>
                  <i>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</i>
                </p>
                <p>
                  We may update this privacy notice from time to time. The updated version will be indicated by an
                  updated "Revised" date and the updated version will be effective as soon as it is accessible. If we
                  make material changes to this privacy notice, we may notify you either by prominently posting a notice
                  of such changes or by directly sending you a notification. We encourage you to review this privacy
                  notice frequently to be informed of how we are protecting your information.
                </p>
              </li>
              <li id="item14">
                <h2>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
                <p>
                  If you have questions or comments about this notice, you may email us at{' '}
                  <a href="mailto:onthejloprivacy@gmail.com">onthejloprivacy@gmail.com</a> or by post to:
                </p>
                <p>Nuyorican Productions, 9200 W. Sunset Blvd Los Angeles, CA 90069 United States</p>
              </li>
              <li id="item15">
                <h2>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
                <p>
                  Based on the applicable laws of your country, you may have the right to request access to the personal
                  information we collect from you, change that information, or delete it. To request to review, update,
                  or delete your personal information, please contact us.
                </p>
              </li>
            </ol>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PrivacyPage;
