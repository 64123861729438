import { IVideoModel } from 'types';
import { GoogleAnalyticsEvent } from 'windowConfig';
import VideoPlayer from '../shared/VideoPlayer';
import usePageView from '../../hooks/usePageView';
import { useLocation } from 'react-router-dom';
import useScriptTracking from 'hooks/useScriptTracking';
import { STORM_GA_PROPERTY_ID } from 'constants/index';

interface IVideoPageProps extends IVideoModel {}

const VideoPage: React.FunctionComponent<IVideoPageProps> = ({ title, videoUrl, playcardUrl }) => {
  const location = useLocation();
  usePageView(`Video Page - ${title}`, location.pathname);
  useScriptTracking();
  return (
    <>
      <div className="logo">
        <img src="https://1770075938.rsc.cdn77.org/onthejlo/logo.png" alt="on-the-jlo-logo" className="img-fluid" />
      </div>
      <VideoPlayer
        videoUrl={videoUrl}
        playcardUrl={playcardUrl}
        onPlayCallback={() =>
          GoogleAnalyticsEvent('event', 'VideoPlay', { event_label: title, send_to: STORM_GA_PROPERTY_ID })
        }
        videoContainerClasses="video-preview video-content"
      />
    </>
  );
};

export default VideoPage;
