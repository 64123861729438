import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => (
  <Col>
    <div className="disclaimer">
      <Link to="/privacy">Privacy Policy</Link>
      <div>Copyright © {new Date().getFullYear()} On The JLo - All Rights Reserved.</div>
    </div>
  </Col>
);

export default Footer;
