import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import CGELandingPage from '../components/pages/CGELandingPage';
import PrivacyPage from '../components/pages/PrivacyPage';
import {
  LandingPageRoute,
  SuccessPageRoute,
  PrivacyPageRoute,
  SignUpPageRoute,
  UnsubscribePageRoute,
} from '../constants/pageRoutes';
import VideoContainerPage from '../components/pages/VideoContainerPage';
import SignUpPage from 'components/pages/SignUpPage';
import UnsubscribePage from 'components/pages/UnsubscribePage';

const AppRoutes: React.FC = () => {
  const location = useLocation();

  return (
    <Routes location={location}>
      <Route path={LandingPageRoute} element={<CGELandingPage />} key="landing" />
      <Route path={SignUpPageRoute} element={<SignUpPage />} key="signup" />
      <Route path={SuccessPageRoute} element={<CGELandingPage success />} key="success" />
      <Route path={'/video/:videoShortCode'} element={<VideoContainerPage />} key="video" />
      <Route path={PrivacyPageRoute} element={<PrivacyPage />} key="privacy" />
      <Route path={UnsubscribePageRoute} element={<UnsubscribePage />} key="unsubscribe" />
      <Route path="*" element={<Navigate to={LandingPageRoute} />} key="notfound" />
    </Routes>
  );
};

export default AppRoutes;
