import React, { useEffect, useRef } from 'react';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import 'video.js/dist/video-js.css';

interface IVideoPlayerProps {
  videoUrl: string;
  playcardUrl: string;
  onPlayCallback?: Function;
  videoContainerClasses?: string;
}

const VideoPlayer: React.FC<IVideoPlayerProps> = ({ videoUrl, playcardUrl, onPlayCallback, videoContainerClasses }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const videoPlayerRef = useRef<VideoJsPlayer | null>(null);

  const videoJsOptions: VideoJsPlayerOptions = {
    autoplay: false,
    bigPlayButton: false,
    playbackRates: [1],
    width: 1920,
    height: 1080,
    controls: true,
    controlBar: {
      pictureInPictureToggle: false,
      volumePanel: false,
      playbackRateMenuButton: false,
      // fullscreenToggle: false,
    },
    sources: [
      {
        src: videoUrl,
        type: 'video/mp4',
      },
    ],
  };

  useEffect(() => {
    function disposePlayer() {
      if (videoPlayerRef.current && videoPlayerRef.current.dispose) {
        videoPlayerRef.current.dispose();
      }
    }

    if (videoRef.current) {
      videoPlayerRef.current = videojs(videoRef.current, videoJsOptions);
      if (!videoPlayerRef.current.src()) {
        videoPlayerRef.current.src(videoJsOptions.sources![0].src);
      }
      videoPlayerRef.current.on('play', () => {
        if (onPlayCallback) {
          onPlayCallback();
        }
      });
    }

    return disposePlayer;
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div data-vjs-player>
        <div className={videoContainerClasses}>
          <video
            ref={(node) => (videoRef.current = node)}
            className="video-js vjs-default-skin vjs-big-play-centered vjs-fluid vjs-16-9"
            controls
            playsInline={true}
            preload="auto"
            poster={playcardUrl}
            data-setup='{"fluid": true, "aspect-ratio":"16:9"}'
          />
        </div>
      </div>
    </>
  );
};

export default VideoPlayer;
