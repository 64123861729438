import { useState } from 'react';

interface ICommentProperties {
  id: number;
  content: string;
  author: string;
  date: string;
  sliceNumber: number;
}
const Comment = ({ comment }: { comment: ICommentProperties }) => {
  const { content, author, date, sliceNumber } = comment;

  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <div className="comment">
      <p className="subheader-text">
        {isReadMore ? content.slice(0, sliceNumber) + '...' : content}&nbsp;
        <span onClick={toggleReadMore} className="read-more">
          {isReadMore ? 'READ MORE' : null}
        </span>
      </p>
      {!isReadMore ? (
        <div className="author-date">
          <span className="author subheader-text">{author}</span> <span className="date">Sent on {date}</span>{' '}
          <span onClick={toggleReadMore} className="read-more">
            SHOW LESS
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default Comment;
