import { DependencyList, useEffect } from 'react';
import { GoogleAnalyticsEvent } from '../windowConfig';
import { STORM_GA_PROPERTY_ID } from 'constants/index';

// TODO - could make pagePath optional
// const location = useLocation();
// use location.pathname when not defined

const usePageView = (pageTitle: string, pagePath: string, dependencyList: DependencyList = []) => {
  useEffect(() => {
    GoogleAnalyticsEvent('event', 'page_view', {
      page_title: pageTitle,
      page_path: pagePath,
      send_to: STORM_GA_PROPERTY_ID,
    });
    // TEMPORARILY DISABLED WHILE EMBEDDED IN OTHER SITE
    // GoogleAnalyticsEvent('event', 'conversion', {
    //   allow_custom_scripts: true,
    //   send_to: 'DC-10536747/rtg/jenni0+standard',
    // });
  }, dependencyList);
};

export default usePageView;
