const Arrow: React.FC = () => (
  <div className="arrow-animate">
    <svg width="653" height="574" viewBox="0 0 653 574" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1438_2)">
        <path
          className="arrow-fill"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.63135 219.118L1.63135 350.723L403.868 350.723L277.976 481.076L372.641 572.5L651.5 283.758L372.072 1.5L278.546 94.089L402.322 219.118L1.63135 219.118Z"
          fill="white"
        />
        <path
          className="arrow-line"
          d="M1.13184 350.723L1.63184 350.723L1.63184 351.223M1.13184 350.723L1.13184 351.223L1.63184 351.223M1.13184 350.723L1.13184 219.118M1.13184 350.723L2.13184 350.723L2.13184 219.118L1.13184 219.118M1.63184 351.223L1.63184 350.223L403.868 350.223M1.63184 351.223L403.868 351.223L403.868 350.223M1.63184 218.618L1.63184 219.118L1.13184 219.118M1.63184 218.618L1.13184 218.618L1.13184 219.118M1.63184 218.618L402.322 218.618L402.322 219.618M1.63184 218.618L1.63184 219.618L402.322 219.618M404.227 351.07L403.868 350.723L403.868 350.223M404.227 351.07L405.046 350.223L403.868 350.223M404.227 351.07L278.336 481.423L277.617 480.728M404.227 351.07L403.508 350.375L277.617 480.728M277.617 480.728L277.976 481.076L277.629 481.435M277.617 480.728L277.269 481.088L277.629 481.435M277.629 481.435L278.324 480.716L372.988 572.14L372.294 572.86M277.629 481.435L372.294 572.86M372.294 572.86L372.641 572.5L373.001 572.847M372.294 572.86L372.653 573.207L373.001 572.847M373.001 572.847L372.281 572.153L651.14 283.41L651.86 284.105M373.001 572.847L651.86 284.105M651.86 284.105L651.5 283.758L651.855 283.406M651.86 284.105L652.199 283.753L651.855 283.406M651.855 283.406L651.145 284.109L371.717 1.85206L372.427 1.14808M651.855 283.406L372.427 1.14808M372.427 1.14808L372.072 1.50007L371.72 1.14508M372.427 1.14808L372.075 0.793095L371.72 1.14508M371.72 1.14508L372.424 1.85505L278.897 94.444L278.194 93.733M371.72 1.14508L278.194 93.733M278.194 93.733L278.546 94.089L278.19 94.44M278.194 93.733L277.839 94.085L278.19 94.44M278.19 94.44L278.901 93.737L402.677 218.766M278.19 94.44L401.966 219.47L402.677 218.766M402.322 219.618L402.322 219.118L402.677 218.766M402.322 219.618L403.52 219.618L402.677 218.766"
          stroke="white"
          strokeWidth={5}
        />
      </g>
      <defs>
        <clipPath id="clip0_1438_2">
          <rect width="573" height="652" fill="white" transform="translate(0.5 573.5) rotate(-90)" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export default Arrow;
