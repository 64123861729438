import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { VideoUrlNameKey, LandingPageRoute } from '../../constants/pageRoutes';
import { IVideoModel } from '../../types';
import VideoEntryPage from './VideoEntryPage';
import VideoPage from './VideoPage';
import Loader from '../shared/Loader';
import { setIsSubscriberLocalStorage, getIsSubscriberLocalStorage } from '../../helpers/LocalStorage';
import defaultHeaders from '../shared/DefaultHeaders';
import clsx from 'clsx';
import SignUpForm from 'components/shared/SignUpForm';
import { Container, Row, Col } from 'react-bootstrap';
import Arrow from 'components/shared/Arrow';
import LogoHeader from 'components/shared/LogoHeader';
import LogoLetters from 'components/shared/LogoLetters';
import useScriptTracking from 'hooks/useScriptTracking';

const notFoundResponseCode = 404;

const videoApiUrl = (urlName: string) => `/api/video/${urlName}`;

const request: AxiosRequestConfig = {
  method: 'GET',
  headers: defaultHeaders,
};

const VideoContainerPage: React.FC = () => {
  const navigate = useNavigate();
  const urlName = useParams<{ [VideoUrlNameKey]: string }>()[VideoUrlNameKey];
  const [isSubscriber, setIsSubscriber] = useState<boolean>(getIsSubscriberLocalStorage());
  const [submittedEmailAddress, setSubmittedEmailAddress] = useState<string>('');
  const [videoDetails, setVideoDetails] = useState<IVideoModel>({
    title: '',
    videoUrl: '',
    playcardUrl: '',
  });
  const [showSignUpForm, setShowSignUpForm] = useState<boolean>(false);

  useEffect(() => {
    if (urlName) {
      Axios({ ...request, url: videoApiUrl(urlName) })
        .then(({ data }: AxiosResponse<IVideoModel>) => {
          setVideoDetails(data);
        })
        .catch((error: Error | AxiosError) => {
          if (Axios.isAxiosError(error) && error.response && error.response.status === notFoundResponseCode) {
            navigate(LandingPageRoute);
          }
        });
    }
  }, []);

  useScriptTracking();
  if (!videoDetails.videoUrl) {
    return <Loader />;
  }

  return isSubscriber ? (
    <VideoPage {...videoDetails} />
  ) : (
    <div className="w-100">
      <div className="jlo-logo-wrapper">
        <div className="jlo-logo-container">
          <a href="/">
            <div className="d-flex gap-2 align-items-end justify-content-center">
              <div className="">
                <LogoHeader />
                <LogoLetters />
              </div>
              <Arrow />
            </div>
          </a>
        </div>
      </div>
      <Container className={clsx('body-content fade-in', { 'sign-in': !showSignUpForm, 'sign-up': showSignUpForm })}>
        <header className="logo-container mb-4 mb-md-5">
          <img
            src="https://1770075938.rsc.cdn77.org/onthejlo/cant-get-enough-landing/images/logo-white.webp"
            alt="Jennifer Lopez"
            className="logo-1"
          />
          <img
            src="https://1770075938.rsc.cdn77.org/onthejlo/cant-get-enough-landing/images/this-is-me-now-logo.svg"
            alt="Jennifer Lopez"
            className="logo-2"
          />
        </header>
        {showSignUpForm && (
          <div className="main-content">
            <Row className="text-center">
              <Col xs={12}>
                <img
                  src="https://1770075938.rsc.cdn77.org/onthejlo/cant-get-enough-landing/images/bird.svg"
                  alt="Bird Icon"
                  className="bird-icon"
                />
                <h1 className="heading text-center">Join...Now</h1>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={12}>
                <p className="header-text">
                  Join On the JLo to get access to news, special experiences, exclusive content and more. <br />
                  <span className="bold">(Just Keep it On the JLo)</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="form-container signup-form">
                <SignUpForm
                  onSuccess={() => {
                    setIsSubscriber(true);
                  }}
                  parentPage={`Video Entry Form - ${videoDetails.title}`}
                  initialEmailAddress={submittedEmailAddress}
                />
              </Col>
            </Row>
          </div>
        )}
        {!showSignUpForm && (
          <Row>
            <Col xs={12} className="form-container signin-form">
              <VideoEntryPage
                videoTitle={videoDetails.title}
                setIsSubscriber={() => {
                  setIsSubscriber(true);
                  setIsSubscriberLocalStorage();
                }}
                onSignUpClick={() => setShowSignUpForm(true)}
                setSubmittedEmailAddress={setSubmittedEmailAddress}
              />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default VideoContainerPage;
