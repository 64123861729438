export const comments = [
  {
    id: 1,
    content: `I absolutely LOVE this movie!!!!! 💕 Aside from being an amazing, beautiful movie, the message/ messages are so profound !  I Don’t accept less than I deserve….don’t give up…. Try try til you get it right …. Most importantly Love yourself !!!`,
    author: 'Benita M.',
    date: '15th Feb',
    sliceNumber: 170,
  },
  {
    id: 2,
    content: `This Is Me… Now was a journey I did not expect. This love story came in a realm of surrealism, music, drama, comedy and top tier choreography. As a long time fan of Jen, I was able to connect dots and appreciate little details like “Dear Ben” playing in the background of the burning letters scene. I can recall advice Jen has given in various interviews being retold throughout the plot (love yourself first, never give up on love). This is everything a true fan has come to know of Jen, in a package that is reimagined through fantasy and love.`,
    author: 'Angel C.',
    date: 'Feb 8th',
    sliceNumber: 268,
  },
  {
    id: 3,
    content: `This Is Me...Now: A Love Story: this was a love story told through music, dance, words, rhiming words and love words. I loved the folklore story of the taino indian turning into a hummingbird that keeps looking for his loved one, his mate, his lover and his everything. Yes i believe in chemistry when it comes to love. In my experience I felt it with one person only so i understand, i connect with your love story very well. And its crazy to feel it with the one person only, because it feels like a magnet this pull towards your loved one and its hard to let go. I identified so much with "Broken like me" and i cried when i heard the song. The story, the words in this song were so powerful, again because i went through it but i was able to deal with it on my own. Hearts and flowers was also another favorite. The visual of dances it showed me the pain the emotion being felt in the heart. The cast was so beautifully and they did a good job expressing the story. Overall thank you for showing us a wonderful love story - the journey in finding your hummingbird!`,
    author: 'Nora C.',
    date: 'Feb 8th',
    sliceNumber: 283,
  },
  {
    id: 4,
    content: `It is an honor to Hear It. See It. Live It… Share It… This Is Me…Now: A Love Story is the Most Amazing Way to Experience The Personal Journey Of Finding Love, through The Heart and Soul of The Incredibly Talented Jennifer Lopez! I am Blessed to have attended the Special Screening in Miami and could not take my eyes off the screen from beginning to end and still sat in thought quite a while after the credits had rolled! The Singing, Dancing and Artistic Process of the Entire Experience was Breathtaking! I am in Awe of the Limitless Ability Jennifer has to tell her story in such a Unique and Impactful Way!`,
    author: 'Trish P.',
    date: 'Feb 8th.',
    sliceNumber: 262,
  },
  {
    id: 5,
    content: `So much emotion, but its beautiful emotion. This Is Me Now: A Love Story was that and so much more. Grateful for you JLo and your constant reminder that the love we truly need is within.`,
    author: 'Julianna S.',
    date: '9th Feb',
    sliceNumber: 262,
  },
  {
    id: 6,
    content: `The quality, the production.. the music! EVERYTHING PHENOMENAL 🤯 So enjoyable, exciting, whimsical yet still very dramatic and over the top, in the best way possible.. UNLIKE ANYTHING WE’VE EVER SEEN FROM JENNIFER BEFORE, I promise! This Is Me…Now is without a doubt This Is Me…Then’s mature, evolved, honest and gorgeous sister. 💯`,
    author: 'Yasmany L.',
    date: '9th Feb',
    sliceNumber: 238,
  },
];
