import { BrowserRouter as Router } from 'react-router-dom';

import './styles/typography.scss';
import AppRoutes from './infrastructure/Routes';
import CookieConsentPopUp from './components/CookieConsentPopUp';
import FacebookPixelWrapper from './components/FacebookPixelWrapper';

function App() {
  return (
    <Router>
      <FacebookPixelWrapper>
        <AppRoutes />
        <CookieConsentPopUp onCookieAccept={() => {}} />
      </FacebookPixelWrapper>
    </Router>
  );
}

export default App;
