import { LandingPageRoute } from 'constants/pageRoutes';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="logo-container mb-4 mb-md-5 text-center">
      <Link to={LandingPageRoute}>
        <img
          src="https://1770075938.rsc.cdn77.org/onthejlo/cant-get-enough-landing/images/logo-white.webp"
          alt="Jennifer Lopez"
          className="logo-1"
        />
        <img
          src="https://1770075938.rsc.cdn77.org/onthejlo/cant-get-enough-landing/images/this-is-me-now-logo.svg"
          alt="Jennifer Lopez"
          className="logo-2"
        />
      </Link>
    </header>
  );
};

export default Header;
